import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Hs1, Hs2, Hs3 } from '../../assets/images';


import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/grittyinpink-social_facebook.png';
import twitterImage from '../../assets/grittyinpink-social_twitter.png';

import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import Feature from './Standalone/Feature';
import PrimaryBtn from './Standalone/PrimaryBtn';
import SectionCategories from './SectionCategories/SectionCategories';
import SectionWhyJoin from './SectionWhyJoin/SectionWhyJoin';
import css from './LandingPage.module.css';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
const MAX_MOBILE_SCREEN_WIDTH = 768;

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, featuredListings, viewport } = props;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
            />
          </div>
          {/* <ul className={css.sections}>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionFilteredSearches />
              </div>
            </li>
          </ul> */}
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionCategories />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                />
              </div>
            </li>
            <li>
              <div id={css.features}>
                <h1 id={css.featureHeader}>Featured talent</h1>
                {
                  isMobileLayout && featuredListings && featuredListings.length ?
                    <Feature tests={featuredListings[0].attributes.description ?? ''} id={featuredListings[0].id.uuid} images={featuredListings[0].images} title={featuredListings[0].attributes.title} index={0} /> : null
                }
                {
                  !isMobileLayout && featuredListings && featuredListings.length ?
                    featuredListings.map((listing, key) => (
                      <Feature tests={listing.attributes.description ?? ''} id={listing.id.uuid} images={listing.images} title={listing.attributes.title} index={key} key={key} />))
                    : null
                }
                {
                  isMobileLayout && <Link to={'/search?pub_isFeatured=true'}>See More...</Link>
                }
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionWhyJoin
                />
              </div>
            </li>
          </ul>
          <div id={css.joinToday}>
            <h1>What are you waiting for?</h1>
            <a href="/signup">
              <PrimaryBtn className={css.suBtn} bg="white" color="#000" text="Sign Up Now" />
            </a>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

  const { currentPageResultIds } = state.SearchPage;
  const featuredListings = getListingsById(state, currentPageResultIds);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    featuredListings
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
