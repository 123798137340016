import React, { Component } from 'react';
import classNames from 'classnames';
import css from '../SectionCategories/SectionCategories.module.css';

class CategoryButton extends Component {

  render() {

    return (
      <div className={css.categoryButtonContainer}>
        <div
          className={css.categoryButton}>
          <a href={this.props.link}>
            <img className={css.catImg} src={this.props.image}/>
            <div className={css.categoryText}>{this.props.title}</div>
          </a>
        </div>
      </div>
    );
  }
}

export default CategoryButton;
