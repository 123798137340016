import React, { Component } from 'react';


class PrimaryBtn extends Component {
  render() {

    const myStyle = {
      color: this.props.color,
      backgroundColor: this.props.bg
    };
    return (
        <button
         className={this.props.className}
         style={myStyle}
         >{this.props.text}
       </button>
    );
  }
}

export default PrimaryBtn;
