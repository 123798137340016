import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import {Image2} from '../../../assets/images';
import PrimaryBtn from '../Standalone/PrimaryBtn';



import css from './SectionWhyJoin.module.css';

const SectionWhyJoin = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={css.sectionContainer}>
    <div className={css.infoBox}>
    <div className={css.imageContainer}>
      <img className={css.image} src={Image2} loading="lazy"/>
    </div>
      <div className={css.title}>
        <h1><FormattedMessage id="SectionWhyJoin.sectionTitle" /></h1>
        <div className={css.steps}>
          <div className={css.step}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionWhyJoin.part1Title" />
            </h2>
          </div>
          <div className={css.step}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionWhyJoin.part2Title" />
            </h2>
          </div>
          <div className={css.step}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionWhyJoin.part3Title" />
            </h2>
          </div>
        </div>
        <a href="/signup">
              <PrimaryBtn className={css.suBtn} color="#f95284" bg="white" text="Join Now!"/>
            </a>
      </div>

    </div>
    </div>
  );
};

SectionWhyJoin.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionWhyJoin.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionWhyJoin;
