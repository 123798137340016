import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image3 } from '../../../assets/images';

import { FormattedMessage } from '../../../util/reactIntl';


import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={css.container}>
      <div className={css.stepdiv}>
        <div className={css.title}>
          <FormattedMessage id="SectionHowItWorks.titleLineOne" />
        </div>

        <div id="steps" className={css.steps}>

          <div className={css.step}>
            <span className={css.stepNum}>1</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part1Title" />
            </h2>
          </div>

          <div className={css.step}>
            <span className={css.stepNum}>2</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part2Title" />
            </h2>
          </div>

          <div className={css.step}>
            <span className={css.stepNum}>3</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part3Title" />
            </h2>
          </div>
        </div>
      </div>
      <div className={css.stepimage}>
        <img src={Image3}/>
      </div>
    </div>

  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
