import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import CategoryButton from '../Standalone/CategoryButton';

import css from './SectionCategories.module.css';

import {
  All,
  Business,
  Graphics,
  Local,
  Marketing,
  Music
} from '../../../assets/images';




const SectionCategories = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage id="SectionCategories.title" />
      </h1>
      <div id={css.categories}>
            <CategoryButton link="/search?pub_parentCategory=music-and-audio" image={Music} title="Music & Audio"/>
            <CategoryButton link="/search?pub_parentCategory=business" image={Business} title="Business"/>
            <CategoryButton link="/search?pub_parentCategory=graphics-and-design" image={Graphics} title="Graphic Design & Media"/>
            <CategoryButton link="/search?pub_parentCategory=marketing" image={Marketing} title="Marketing"/>
            <CategoryButton link="/search?pub_parentCategory=local-services" image={Local} title="Local Services"/>
            <CategoryButton link="/search?pub_parentCategory=live-talent" image={All} title="Live Talent"/>
      </div>
    </div>
  );
};

SectionCategories.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategories;
