import React, { Component } from 'react';
import css from '../LandingPage.module.css';
import { ResponsiveImage } from '../../../components';
import { NamedLinkComponent } from '../../../components/NamedLink/NamedLink';
import { createSlug } from '../../../util/urlHelpers';


class Feature extends Component {
  constructor() {
    super();
    this.state = { showBox: false }
  }
  handleBoxToggle = () => this.setState({ showBox: !this.state.showBox });
  render() {
    const showStyle = this.state.showBox ? "show" : "";
    const indexStyle = "featureTest1";
    const variantPrefix = 'listing-card'
    const firstImage = this.props.images && this.props.images.length ? this.props.images[0] : null;
    const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

    return (
      <div className={css.featureContainer}>
        <div
          className={css.featureButton}>
          <NamedLinkComponent name='ListingPage' params={{slug: createSlug(this.props.title), id: this.props.id}}>
            <ResponsiveImage className={css.featureImg} image={firstImage} alt='Featured' variants={variants}/>
            <div className={css.featureText}>{this.props.title}</div>
          </NamedLinkComponent>

          {/* <motion.div variants={variants} animate={this.state.showBox ? "animate" : "initial"} className={`${css[showStyle]} ${css[indexStyle]} ${css.featureTest}`}>
            <h1>Has worked with:</h1>
            <h2>{this.props.tests}</h2>
          </motion.div> */}
        </div>
        {/* <div className={` ${css[indexStyle]} ${css.featureTest}`}>
          <h1>Has worked with:</h1>
          <h2>{this.props.tests}</h2>
        </div> */}

      </div>
    );
  }
}

export default Feature;
